<template lang="html">
  <div class="centerx popup-description">
    <span @click="popupActivo = true" color="primary" type="border">{{ processDescription() }}</span>
    <vs-popup contentClass="toolbarWhite" colorCard="white" :light="true" class="holamundo" :title="title" :active.sync="popupActivo">
      <div class="text-black text-left description px-3 py-3" v-html="description"></div>
    </vs-popup>
  </div>
</template>

<script>
import Vue from 'vue';

function html2text(html) {
  const d = document.createElement('div');
  d.innerHTML = html;
  return d.textContent;
}

export default {
  props: {
    description: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    'vs-popup': () => import('@/components/Popup/VSPopup.vue')
  },
  data() {
    return {
      popupActivo: false
    };
  },
  methods: {
    processDescription() {
      let sizeString = 70;
      const wSize = this.windowWidth;
      if (wSize < 768) {
        sizeString = 80;
      }
      if (wSize < 500) {
        sizeString = 15;
      }
      return this._.truncate(html2text(this.description), {
        length: sizeString,
        separator: '...'
      });
    }
  }
};
</script>

<style lang="scss">
.popup-description span {
  cursor: pointer;
}
.toolbarWhite {
  .v-toolbar__title {
    color: white;
  }
}
</style>
